import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-pay',
  templateUrl: './page-pay.component.html',
  styleUrls: ['./page-pay.component.css']
})
export class PagePayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
