import { Component, OnInit } from '@angular/core';
//import * as firebase from 'firebase';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'app';

  ngOnInit() {
    // firebase.initializeApp({
    //   apiKey: "AIzaSyBNaUbGsKMQkHyHvZc3t5iEl1eAjFleo48",
    //   authDomain: "teacher-schedule-6b3dd.firebaseapp.com"
    // });
  }
}
