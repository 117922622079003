import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-slide-show',
  templateUrl: './slide-show.component.html',
  styleUrls: ['./slide-show.component.css']
})
export class SlideShowComponent implements OnInit {

  constructor() { }
  imageUrls = [
    //{ url: 'assets/img/s1.jpg', caption: 'The first slide', href: '#config' },
    { url: 'assets/img/s1.jpg' },
    { url: 'assets/img/s2.jpg' },
    { url: 'assets/img/s3.jpg' },
    'assets/img/s4.jpg'
  ];
  height: string = '450px';
  minHeight: string;
  arrowSize: string = '20px';
  showArrows: boolean = true;
  disableSwiping: boolean = false;
  autoPlay: boolean = true;
  autoPlayInterval: number = 3333;
  stopAutoPlayOnSlide: boolean = true;
  debug: boolean = false;
  backgroundSize: string = '100% 100%';
  backgroundPosition: string = 'center center';
  backgroundRepeat: string = 'no-repeat';
  showDots: boolean = true;
  dotColor: string = '#FFF';
  showCaptions: boolean = true;
  captionColor: string = '#FFF';
  captionBackground: string = 'rgba(0, 0, 0, .35)';
  lazyLoad: boolean = false;
  width: string = '100%';
  ngOnInit() {
    // adding an image url dynamically.
}
}
