import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  constructor() { }
  spinner = true;
  spinner2 = false;
  isIn = false;   // store state
    toggleState() { // click handler
        let bool = this.isIn;
        this.isIn = bool === false ? true : false;
        this.spinner = bool === true ? true : false;
        this.spinner2 = bool === false ? true : false;
    }
  ngOnInit() {
  }

}
