import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ScienceCampComponent } from './scienceCamp/scienceCamp.component';
import { PageHomeComponent } from './homepage-directory/page-home/page-home.component';
import { PageTeamComponent } from './page-directory/page-team/page-team.component';
import { PageAboutComponent } from './page-directory/page-about/page-about.component';
import { PageClassComponent } from './page-directory/page-class/page-class.component';
import { PagePriceComponent } from './page-directory/page-price/page-price.component';
import { PageLessonComponent } from './page-directory/page-lesson/page-lesson.component';
import { PagePayComponent } from './page-directory/page-pay/page-pay.component';
import { PageCampComponent } from './page-directory/page-camp/page-camp.component';
import { PageAdmissionComponent } from './page-directory/page-admission/page-admission.component';
import { PageScheduleComponent } from './page-directory/page-schedule/page-schedule.component';
import { PageContactComponent } from './page-directory/page-contact/page-contact.component';

const appRoutes: Routes = [
  { path: '', component: PageHomeComponent },
  { path: 'home', component: PageHomeComponent },
  { path: 'about', component: PageAboutComponent },
  { path: 'team', component: PageTeamComponent },
  { path: 'class', component: PageClassComponent },
  { path: 'price', component: PagePriceComponent },
  { path: 'lesson', component: PageLessonComponent },
  { path: 'pay', component: PagePayComponent },
  { path: 'camp', component: PageCampComponent },
  { path: 'admission', component: PageAdmissionComponent },
  { path: 'schedule', component: PageScheduleComponent },
  { path: 'contact', component: PageContactComponent },
  { path: 'scienceCamp', component: ScienceCampComponent },
  { path: '**', redirectTo: '/home' },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes,{scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})

export class AppRoutingModule{

}
