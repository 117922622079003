import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { SlideshowModule } from 'ng-simple-slideshow';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule }   from '@angular/forms';
import { ScienceCampComponent } from './scienceCamp/scienceCamp.component';
//homepage-directory
import { PageHomeComponent } from './homepage-directory/page-home/page-home.component';
import { HeaderComponent } from './homepage-directory/header/header.component';
import { ClassComponent } from './homepage-directory/class/class.component';
import { FooterComponent } from './homepage-directory/footer/footer.component';
import { SlideShowComponent } from './homepage-directory/slide-show/slide-show.component';
import { SloganComponent } from './homepage-directory/slogan/slogan.component';
import { PriceComponent } from './homepage-directory/price/price.component';
import { TeamComponent } from './homepage-directory/team/team.component';
//page-directory
import { PageTeamComponent } from './page-directory/page-team/page-team.component';
import { PageAboutComponent } from './page-directory/page-about/page-about.component';
import { PageClassComponent } from './page-directory/page-class/page-class.component';
import { PagePriceComponent } from './page-directory/page-price/page-price.component';
import { PageLessonComponent } from './page-directory/page-lesson/page-lesson.component';
import { PagePayComponent } from './page-directory/page-pay/page-pay.component';
import { PageCampComponent } from './page-directory/page-camp/page-camp.component';
import { PageAdmissionComponent } from './page-directory/page-admission/page-admission.component';
import { PageScheduleComponent } from './page-directory/page-schedule/page-schedule.component';
import { PageContactComponent } from './page-directory/page-contact/page-contact.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatCardModule} from '@angular/material/card';
import { MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatTableModule,
        MatExpansionModule } from '@angular/material';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';     

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SlideShowComponent,
    SloganComponent,
    ClassComponent,
    PriceComponent,
    TeamComponent,
    FooterComponent,
    PageHomeComponent,
    PageTeamComponent,
    PageAboutComponent,
    PageClassComponent,
    PagePriceComponent,
    PageLessonComponent,
    PagePayComponent,
    PageCampComponent,
    PageAdmissionComponent,
    PageScheduleComponent,
    PageContactComponent,
    ScienceCampComponent
  ],
  imports: [
    BrowserModule,
    SlideshowModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    MatTabsModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTableModule,
    MatExpansionModule,
    MatCardModule,
    NgbModule.forRoot()
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
